var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app',{attrs:{"id":"app"}},[(
        _vm.$route.name != 'Login' &&
        _vm.$route.name != 'SignUp' &&
        _vm.$route.name != 'ForgotPassword' &&
        _vm.$route.name != 'Home'
      )?_c('span',[_c('AppHeader'),_c('v-divider')],1):_vm._e(),_c('span',{staticStyle:{"min-height":"100vh"}},[_c('router-view',{key:_vm.$route.fullPath,attrs:{"storage":_vm.sideNav}})],1),(
        _vm.$route.name != 'Login' &&
        _vm.$route.name != 'SignUp' &&
        _vm.$route.name != 'ForgotPassword'
      )?_c('AppFooter'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }