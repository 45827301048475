<template>
  <div id="appNavbar">
    <ServerError v-if="ServerError" />
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#202020">
      <v-layout wrap justify-center>
        <v-flex
          xs12
          v-for="(item, i) in navItems"
          :key="i"
          text-center
          pa-2
          pl-4
          text-uppercase
          align-self-center
        >
          <v-layout wrap justify-center>
            <v-flex xs12>
              <router-link :to="item.route">
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'poppinsbold',
                        }
                      : {
                          'font-family': 'poppinsthin',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: #ffffff;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
              </router-link>
            </v-flex>
            <v-flex xs12 pt-2>
              <v-divider color="#fff"></v-divider>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 text-uppercase align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <span
                @click="appLogout"
                style="
                  font-family: poppinsthin;
                  font-size: 15px;
                  color: #ffffff;
                  letter-spacing: 3px;
                  cursor: pointer;
                "
              >
                Logout
              </span>
            </v-flex>
            <v-flex xs12 pt-2>
              <v-divider color="#fff"></v-divider>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="#ffffff"
      dense
      flat
      height="50px"
      class="hidden-lg-and-up"
    >
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="#ff0000">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <span
                style="
                  font-family: poppinsbold;
                  font-size: 20px;
                  cursor: pointer;
                  color: #545454;
                "
              >
                Hive Keep
              </span>
            </v-flex>
          </v-layout>
        </router-link>
      </v-toolbar-title>
      <v-app-bar-nav-icon
        v-if="appType == 'Client'"
        class="hidden-lg-and-up"
        @click.native="sideNav2 = !sideNav2"
      >
        <v-icon color="#ff0000">mdi-account</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-app-bar
      color="#ffffff"
      elevation="0"
      height="70px"
      class="hidden-md-and-down"
    >
      <v-layout wrap justify-center fill-height>
        <v-flex xs2 align-self-center>
          <!-- <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
            <v-icon color="#ff0000">mdi-menu</v-icon>
          </v-app-bar-nav-icon> -->
          <router-link to="/">
            <span
              style="
                font-family: poppinsbold;
                font-size: 20px;
                cursor: pointer;
                color: #545454;
              "
            >
              Hive Keep
            </span>
          </router-link>
        </v-flex>
        <v-spacer></v-spacer>
        <!-- <v-flex xs2 lg2 xl1 align-self-center>
          <v-form @submit.prevent="appSearch">
            <v-text-field
              class="searchBox"
              v-model="searchKey"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search for Hives"
              solo
              flat
              hide-details
              color="#FF0F0F"
            ></v-text-field>
          </v-form>
        </v-flex> -->
        <v-flex xs6 lg1 xl1 px-4 text-center align-self-center>
          <AccountMenu />
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>
<script>
import store from "../../store";
import AccountMenu from "./accountMenu";
export default {
  components: {
    AccountMenu,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      sideNav: false,
      sideNav2: false,
      user: {},
      navItems: [
        { name: "Dashboard", route: "/Dashboard" },
        { name: "Home", route: "/Home" },
      ],
      categories: [],
      stylCategories: [],
      userName: null,
      profilePic: null,
      cartItems: 0,
      searchKey: null,
    };
  },
  beforeMount() {
    this.searchKey = this.$route.query.searchKey;
  },
  computed: {
    appUser() {
      return store.state.userData;
    },
    appLogin() {
      return store.state.isLoggedIn;
    },
    appType() {
      return store.state.userType;
    },
  },

  methods: {
    appLogout() {
      store.commit("logoutUser", true);
      if (this.sideNav2 == true) {
        this.sideNav2 = false;
      }
    },
    appSearch() {
      if (this.appType == "Client") {
        if (this.$route.query.searchKey != this.searchKey) {
          this.$router.push({
            path: "/Products",
            query: {
              searchKey: this.searchKey,
            },
          });
        } else {
          return;
        }
      } else {
        if (this.$route.query.searchKey != this.searchKey) {
          this.$router.push({
            path: "/Seller/Inventory",
            query: {
              searchKey: this.searchKey,
            },
          });
        } else {
          return;
        }
      }
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>

