// App Color Code : #FDCE48
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
import VueElementLoading from 'vue-element-loading'
import ServerError from './components/Common/500'
import PageLoader from './components/Common/pageLoader'
import ImageLoader from './components/Common/imageLoader'
import ImageViewer from './components/Common/imageViewer'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.component('VueElementLoading', VueElementLoading)
Vue.component('PageLoader', PageLoader)
Vue.component('ImageLoader', ImageLoader)
Vue.component('ServerError', ServerError)
Vue.component('ImageViewer', ImageViewer)

Vue.config.productionTip = false

window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.50.75:2000';
axios.defaults.baseURL = 'http://65.2.128.31/';
Vue.prototype.baseURL = "http://65.2.128.31/"
axios.defaults.iconURL = 'http://arhiveadmin.leopardtechlabs.com/img/';
Vue.prototype.mediaURL = "http://65.2.128.31/u/"
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')