import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		isLoggedIn: !!localStorage.getItem("token"),
		userType: localStorage.getItem('userType'),
		userData: {},
	},
	mutations: {
		loginUser(state, item) {
			localStorage.setItem("token", item);
			state.isLoggedIn = true
			if (state.userType == 'Admin') router.push('/Admin')
			else router.push('/Dashboard')
		},
		logoutUser(state) {
			axios({
				method: "GET",
				url: "/user/logout",
				headers: {
					"x-auth-token": localStorage.getItem("token")
				}
			}).then(response => {
				if (response.data.status) {
					state.userType = null
					state.isLoggedIn = false
					state.userData = {}
					localStorage.removeItem("userType");
					localStorage.removeItem("token");
					router.push('/Login')
				}
			})
		},
		userData(state, item) {
			state.userData = item
		},
		userType(state, item) {
			localStorage.setItem("userType", item);
			state.userType = item
		},
		sessionOut(state) {
			localStorage.removeItem("token");
			state.isLoggedIn = false
		}
	}

})