import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import axios from 'axios'

Vue.use(Router)
let router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            props: true,
            component: {
                render(c) { return c('router-view') },
            },
            meta: {
                requiresAuth: false,
            },
            children: [{
                    path: '',
                    name: 'Home',
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ('./components/Common/appHome')
                },
                {
                    path: 'Login',
                    name: 'Login',
                    props: true,
                    component: () =>
                        import ('./components/Common/appLogin'),
                    meta: {
                        requiresAuth: false,
                    }
                },
                // {
                // 	path: 'SignUp',
                // 	name: 'SignUp',
                // 	props: true,
                // 	component: () => import('./components/Common/appSignUp'),
                // 	meta: {
                // 		requiresAuth: false,
                // 	}
                // },
                {
                    path: 'ForgotPassword',
                    name: 'ForgotPassword',
                    props: true,
                    component: () =>
                        import ('./components/Common/appForgotPassword'),
                    meta: {
                        requiresAuth: false,
                    }
                },
                {
                    path: '/',
                    component: () =>
                        import ('./components/Common/appDashboard'),
                    meta: {
                        requiresAuth: true,
                    },
                    children: [{
                            path: 'Dashboard',
                            name: 'Dashboard',
                            component: () =>
                                import ('./components/User/Dashboard/dashboard'),
                            meta: {
                                requiresAuth: true,
                            },
                        },
                        {
                            path: 'Hive/:id',
                            name: 'Hive',
                            props: true,
                            component: () =>
                                import ('./components/Hive/hive')
                        },
                        {
                            path: 'Apiary/:id',
                            name: 'Apiary',
                            props: true,
                            component: () =>
                                import ('./components/Apiary/apiary')
                        },
                        {
                            path: 'Event/:id',
                            name: 'Event',
                            props: true,
                            component: () =>
                                import ('./components/Event/event')
                        },
                        {
                            path: 'Profile',
                            name: 'Profile',
                            component: () =>
                                import ('./components/Profile/profile'),
                            meta: {
                                requiresAuth: true,
                            },
                        },
                        {
                            path: 'Apiary',
                            name: 'Apiaries',
                            component: () =>
                                import ('./components/User/Apiaries/apiaries')
                        },
                        {
                            path: 'Hives',
                            name: 'Hives',
                            component: () =>
                                import ('./components/User/Hives/hives')
                        },
                        {
                            path: 'Events',
                            name: 'Events',
                            component: () =>
                                import ('./components/User/Events/events')
                        },
                        {
                            path: 'Inventory',
                            name: 'Inventory',
                            component: () =>
                                import ('./components/User/inventory')
                        },
                        {
                            path: 'Supervisors',
                            name: 'Supervisors',
                            component: () =>
                                import ('./components/User/Supervisor/supervisors')
                        },
                        {
                            path: 'Supervisors/:id',
                            name: 'Supervisor',
                            props: true,
                            component: () =>
                                import ('./components/User/Supervisor/supervisor')
                        },
                    ]
                },
            ]
        },
        {
            path: '/Admin',
            component: () =>
                import ('./components/Common/appDashboard'),
            meta: {
                requiresAuth: true,
            },
            children: [{
                    path: '',
                    name: 'AdminDashboard',
                    component: () =>
                        import ('./components/Admin/Dashboard/dashboard')
                },
                {
                    path: 'Users',
                    name: 'Users',
                    component: () =>
                        import ('./components/Admin/Users/users')
                },
                {
                    path: 'Users/Add',
                    name: 'AddUser',
                    component: () =>
                        import ('./components/Admin/Users/addUser')
                },
                {
                    path: 'Users/:id',
                    name: 'UserDetails',
                    props: true,
                    component: () =>
                        import ('./components/Admin/Users/user')
                },
                {
                    path: 'Apiary',
                    name: 'AdminApiaries',
                    component: () =>
                        import ('./components/Admin/Apiaries/apiaries')
                },
                {
                    path: 'Hives',
                    name: 'AdminHives',
                    component: () =>
                        import ('./components/Admin/Hives/hives')
                },
                {
                    path: 'Events',
                    name: 'AdminEvents',
                    component: () =>
                        import ('./components/Admin/Events/events')
                },
                {
                    path: 'Inventory',
                    name: 'AdminInventory',
                    component: () =>
                        import ('./components/Admin/Inventory/inventory')
                },
                {
                    path: 'Map',
                    name: 'AdminMap',
                    component: () =>
                        import ('./components/Admin/Map/map')
                },
            ]
        },

        {
            path: '/Supervisor',
            component: () =>
                import ('./components/Common/appDashboard'),
            meta: {
                requiresAuth: true,
            },
            children: [{
                    path: 'Dashboard',
                    name: 'SupervisorDashboard',
                    component: () =>
                        import ('./components/Supervisor/Dashboard/dashboard'),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: 'Hives',
                    name: 'SupervisorHives',
                    component: () =>
                        import ('./components/Supervisor/Hives/hives')
                },
                {
                    path: 'Events',
                    name: 'SupervisorEvents',
                    component: () =>
                        import ('./components/Supervisor/Events/events')
                },
                {
                    path: 'Events/:id',
                    name: 'SupervisorEvent',
                    props: true,
                    component: () =>
                        import ('./components/Supervisor/Events/event')
                },
            ]
        },
        {
            path: '/ServerError',
            name: 'ServerError',
            props: true,
            component: () =>
                import ('./components/Common/500'),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: '*',
            name: '404PageNotFound',
            props: true,
            component: () =>
                import ('./components/Common/404'),
            meta: {
                requiresAuth: false,
            }
        },

    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})
router.beforeEach((to, from, next) => {

    if (to.matched.some(route => route.meta.requiresAuth == true) && store.state.isLoggedIn == false) {
        console.log(from)
        next({ name: 'Login', params: { lastPage: from } })
        return
    }
    if (store.state.isLoggedIn == true) {
        axios({
                method: 'POST',
                url: '/validate/token',
                headers: {
                    'x-auth-token': localStorage.getItem('token')
                }
            }).then(response => {
                if (!response.data.status) {
                    store.commit("sessionOut", true)
                    return
                }
            })
            .catch(err => {
                var msg = err;
                console.log(msg)
            });
    }
    if ((to.name == 'Login' || to.name == 'Home') && store.state.isLoggedIn == true) {
        next({ name: 'Dashboard' })
        return
    }
    next()
})

export default router