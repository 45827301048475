<template>
  <div>
    <v-app id="app">
      <span
        v-if="
          $route.name != 'Login' &&
          $route.name != 'SignUp' &&
          $route.name != 'ForgotPassword' &&
          $route.name != 'Home'
        "
      >
        <AppHeader />
        <v-divider></v-divider>
      </span>
      <span style="min-height: 100vh">
        <router-view :key="$route.fullPath" v-bind:storage="sideNav" />
      </span>
      <AppFooter
        v-if="
          $route.name != 'Login' &&
          $route.name != 'SignUp' &&
          $route.name != 'ForgotPassword'
        "
      />
    </v-app>
  </div>
</template>
<script>
import AppHeader from "./components/Common/appHeader";
import AppFooter from "./components/Common/appFooter";
import axios from "axios";
import store from "./store";

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      sideNav: false,
    };
  },
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("userData", response.data.data);
            store.commit("userType", response.data.role);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {},
};
</script>